@media print {
  @page {
    size: A4;
    margin: 8mm;
  }
  body {
    margin: 0;
  }
  header, footer {
    display: none;
  }
}

.sheet {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-template-rows: repeat(3, 1fr); 
  gap: 5mm; 
  width: 275mm; 
  height: 277mm; 
}

.label-container {
  width: 100%;
  height: 100%;
}

.recipient-title {
  font-weight: bold;
  font-size: 24px;
}

.box-recipient p {
  margin-bottom: 0;
  font-size: 22px;
}

.box-recipient {
  border: 2px dotted black;
  padding: 5px;
}

.box-sender p {
  margin-bottom: 0;
  font-size: 18px;
}
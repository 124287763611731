.custom-btn {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: #785d80;
    border: 1px solid #785d80;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-decoration: none;
}

.custom-btn:hover {
    color: #fff;
    background-color:  #785d80;
    border-color:   #785d80;
    text-decoration: none;
}

.custom-btn:focus, .custom-btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-btn:active, .custom-btn.active {
    background-color:  #785d80;
    border-color:  #785d80;
}

.custom-btn:disabled, .custom-btn.disabled {
    opacity: 0.65;
}

.custom-btn-primary {
    background-color:  #785d80;
    border-color: #785d80;
}

.custom-btn-primary:hover {
    background-color:  #48384d;
    border-color: #48384d;
}

.custom-btn-primary:focus, .custom-btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 0, 255, 0.5);
}

.custom-btn-primary:disabled, .custom-btn-primary.disabled {
    background-color: #785d80;
    border-color: #785d80;
}

.custom-btn-primary:active, .custom-btn-primary.active {
    background-color: #785d80;
    border-color: #785d80;
}

.container {
  margin-top: 50px;
}

.card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  margin-bottom: 20px;
  font-weight: bold;
}

.form-text {
  display: block;
  margin-top: 5px;
  color: #007bff;
  text-decoration: none;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

body {
  background-color: #785d80;
}